const initState = {
    timeState: false
}

const timeReducer = (state = initState, action : any) =>{
    switch(action.type){
        case 'END_TIME':
            return {
                ...state,
                timeState: action.payload
            }
        default:
            return state    
    }
}

export default timeReducer;