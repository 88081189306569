import React, {useState} from "react";

interface SelectItem {
  id: number;
  text: string;
  icon: string;
}

interface SelectFieldProps {
  options: SelectItem[];
  setSelect? : any
}

function SelectField({options, setSelect}: SelectFieldProps) {
  const [select, setCurrentSelect] = useState<number>(0);
  const onSelect = (index: number) => {
    setSelect(index);
    setCurrentSelect(index);
  };
  

  const [open, setOpen] = useState<boolean>(false);
  const toggle = () => setOpen((v) => !v);

  return (
    <div className="relative">
      <div className="flex gap-1.5 items-center" role="button" onClick={toggle}>
        <div
          className="w-8 h-8 rounded-full flex items-center 5 justify-center"
          style={{
            backgroundImage: `linear-gradient(126.6deg, rgba(171, 196, 255, 0.2) 28.69%, rgba(171, 196, 255, 0) 100%)`,
          }}
        >
          <img className="w-5" src={options[select].icon} alt="" />
        </div>
        <span className="text-white text-md uppercase block mr-0.5">
          {options[select].text}
        </span>
        <img
          className={`duration-200 ${open ? "rotate-180" : ""}`}
          src="/images/svg.svg"
          alt=""
        />
      </div>
      {open && (
        <div className="absolute rounded-md top-9 w-full">
          {options.map(({icon, text, id}, index) => (
            <div
              key={id}
              role="button"
              className="text-white px-3 py-2 bg-gray-600 hover:bg-gray-500 first:rounded-t-md last:rounded-b-md flex text-md items-center gap-2"
              onClick={() => (toggle(), onSelect(index))}
            >
              <img className="w-4" src={icon} alt="" />
              <span>{text}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SelectField;
