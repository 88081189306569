import React from "react";
import ReactSlider from "react-slider";

const datam = [
  {
    id: 6343,
    round: "Round 1",
    name: "KEC Price",
    value: "$0.008",
  },
  {
    id: 7546,
    round: "Round 2",
    name: "KEC Price",
    value: "$0.009",
  },
  {
    id: 8565,
    round: "Round 3",
    name: "KEC Price",
    value: "$0.010",
  },
  {
    id: 9675,
    round: "Round 4",
    name: "KEC Price",
    value: "$0.012",
  },
];

function RoundSlider() {
  return (
    <div>
      <div className="max-w-[480px] xl:max-w-[562px]">
        <div className="h-[48px]">
          <ReactSlider
            className="horizontal-slider"
            marks
            markClassName="mark"
            min={1}
            max={5}
            thumbClassName="thumb"
            trackClassName="track"
            renderThumb={(props, state) => <div {...props} />}
          />
        </div>
        <div className="flex -mx-[43px] xl:-mx-[53px] justify-between -translate-y-[28px]">
          <span className="w-[25%]" />
          {datam.map(({id, name, round, value}) => (
            <div className="w-[25%]" key={id}>
              <div className="flex flex-col items-center">
                <img src="/images/Line 245.svg" alt="" />
                <strong className="font bold  text-md leading-6 text-gray-25">
                  {round}
                </strong>
                <p className="text-sm text-gray-100">{name}</p>
                <b className="font-bold text-xl leading-7 text-primary-700">
                  {value}
                </b>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default RoundSlider;
