import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./components/layout/Layout";
import "./styles/main.css";
import "./styles/responsive.css";
import Home from "./views/Home/Home";
import MoreInfo from "./views/MoreInfo/MoreInfo";
import PrivateSale from "./views/PrivateSale/PrivateSale";

function App() {

  const loadable = useSelector((s: any) => s.load.loadState);
  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
  },[])
  return (
    <div>
      {
        loadable &&
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      }

      <BrowserRouter>
        <Routes>

          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="private-sale" element={<PrivateSale />} />
            <Route path="more-info" element={<MoreInfo />} />
          </Route>
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </div>



  );
}

export default App;
