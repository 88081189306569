
import { toast } from "react-toastify";

export function reduceWallet(address : string){
    return address.substring(0, 6) + "..." + address.substring(address.length - 6);
}

export const tips = (html: string) => {
    toast(html, {
        position: "top-right",
        autoClose: 2000,
        /* hideProgressBar: false, */
        /* closeOnClick: true,
        pauseOnHover: true, */
        /* draggable: true, */
        /* progress: undefined, */
    });
};