import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "../../components/common/Button/Button";

interface HeaderProps {
  toggleModal?: () => void;
}

function Header({ toggleModal }: HeaderProps) {
  const end_time = useSelector((s: any) => s.time.timeState);
  return (
    <section className="flex md:flex-row flex-col md:gap-0 gap-10 pt-16 pb-6 md:py-28 items-center overflow-hidden mb-24 md:mb-8 lg:mb-0">
      <div className="md:text-left text-center ">
        <h1 className="font-manrope font-extrabold text-7xl mb-1 text-gray-25">
          Kaichain
        </h1>
        <h4 className="font-manrope font-extrabold text-3xl text-gray-25 mb-6">
          The Technology of Flawless Transaction
        </h4>
        <p className="text-gray-100 text-xl mb-16">
          Kaichain is an upcoming blockchain network that overcomes the current
          flaws in major ecosystems, such as higher TPS, lower operational costs
          and environment-friendly transactions and EVM compatible.
        </p>
        <div
          className="flex justify-center md:justify-start"
          style={{ "flexWrap": "wrap" }}
        >
          {
            end_time ?
              (
                <Button
                  size="large" margin="10px"
                >Buy the Private Sale</Button>
              ) :
              (
                <Button
                  size="large" margin="10px"
                  onClick={toggleModal}
                >Buy the Private Sale</Button>
              )
          }

          <Button size="large" margin="10px" to={"//medium.com/@kaichain.net/welcome-to-kaichain-the-technology-of-flawless-transaction-6e20173fa15b"}>
            Private Sale Announcement
          </Button>
        </div>
      </div>
      <div className="">
        <div className="xl:pr-[83px] md:pl-[53px]">
          <img
            className="w-[385px] max-w-none"
            src="/images/image 18.png"
            alt=""
          />
        </div>
      </div>
    </section>
  );
}

export default Header;
