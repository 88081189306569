const initState = {
    loadState: false
}

const loadReducer = (state = initState, action : any) =>{
    switch(action.type){
        case 'CHANGE_LOAD':
            return {
                ...state,
                loadState: action.payload
            }
        default:
            return state    
    }
}

export default loadReducer;