import React from 'react'
import { Provider } from 'react-redux'
import { RefreshContextProvider } from './contexts/RefreshContext'
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from "@ethersproject/providers";
import { configureStore } from './state';
import { WalletProvider } from 'contexts/WalletContext';

const store = configureStore(undefined);
function getLibrary(provider: any) {
    return new Web3Provider(provider);
}

const NetworkContextName = `${new Date().getTime()}-NETWORK`;
const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);
const Providers = ({ children }) => {
    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <Web3ProviderNetwork getLibrary={getLibrary}>
                <Provider store={store}>
                    <WalletProvider>
                        <RefreshContextProvider>
                            {children}
                        </RefreshContextProvider>
                    </WalletProvider>
                </Provider>
            </Web3ProviderNetwork>
        </Web3ReactProvider>
    )

}

export default Providers;