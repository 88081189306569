import React, { useState, useEffect } from "react";
import { Collapse } from "react-collapse";
import ScrollContainer from "react-indiana-drag-scroll";
import useWebSocket from "react-use-websocket";

function Statusbar() {

  //const web3 = useWeb3();

  //web3.eth.transactionConfirmationBlocks().then(console.log)
  const [option, setOption] = useState<boolean>(false);
  const toggle = () => setOption((v) => !v);
  const [deployer, setDeployer] = useState(0);
  const [average, setAverage] = useState("0 seconds");
  const [blocks, setBlocks] = useState(0);
  const [transactions, setTranactions] = useState(0);
  const [gas, setGas] = useState("0");
  const { sendJsonMessage, getWebSocket, sendMessage } = useWebSocket('wss://explorer.kaichain.net/socket/websocket?locale=en&vsn=2.0.0', {
    onOpen: () => {
      setTimeout(() => {
        sendMessage('["12", "12", "blocks:new_block", "phx_join", {}]');
        sendMessage('["9","9","addresses:new_address","phx_join",{}]');
        sendMessage('["15","15","transactions:new_transaction","phx_join",{}]');
        sendMessage('["18","18","transactions:stats","phx_join",{}]');
      }, 3000);


    },
    onClose: () => console.log('WebSocket connection closed.'),
    shouldReconnect: (closeEvent) => true,
    onMessage: (event: WebSocketEventMap['message']) => processMessages(event)
  });

  let initialCount = 19;
  const processMessages = (event: { data: string; }) => {
    const response = JSON.parse(event.data);

    switch (response[0]) {
      case "9":
        if (response[4].count) {
          setDeployer(response[4].count)
        }
        break;
      case "12":
        if (response[4].average_block_time) {
          setAverage(response[4].average_block_time);
        };
        if (response[4].block_number) {
          if (response[4].block_number != blocks) {
            setBlocks(response[4].block_number);

          }

        }
        break;
    }

  };

  useEffect(() => {
    setInterval(() => {
      initialCount++;
      let request = '[null' + ',"' + initialCount + '","phoenix","heartbeat",{}]';
      sendMessage(request);
    }, 20000);
  }, [1000]);


  return (
    <section className="md:bg-primary-700 text-gray-1000 border-y border-gray-1000">
      <div className="container md:hidden relative z-10">
        <div className="border border-gray-1000">
          <div
            onClick={toggle}
            role="button"
            className="flex justify-center p-4 bg-primary-700"
          >
            <img
              className={`duration-200 ${option ? "rotate-180" : ""}`}
              src="/images/Down Circle 1.svg"
              alt=""
            />
          </div>
          <Collapse isOpened={option}>
            <div className="relative">
              <div className="flex flex-col absolute left-0 right-0 md:hidden border-t border-gray-1000">
                <div className={`flex border-b md:border-b-0 last:border-b-0 bg-primary-700 md:border-r first:border-l-0 last:border-r-0 border-gray-1000  py-4 md:py-8 md:px-9`}>
                  <div className="min-h-[48px] flex-grow flex items-center justify-between gap-10">
                    <span className="text-md uppercase">block time</span>
                    <span className="font-manrope font-medium text-4xl whitespace-nowrap" style={{ fontSize: "32px" }}>
                      {average}
                    </span>
                  </div>
                </div>

                <div className={`flex border-b md:border-b-0 last:border-b-0 bg-primary-700 md:border-r first:border-l-0 last:border-r-0 border-gray-1000  py-4 md:py-8 md:px-14`}>
                  <div className="min-h-[48px] flex-grow flex items-center justify-between gap-10">
                    <span className="text-md uppercase">current block</span>
                    <span className="font-manrope font-medium text-4xl whitespace-nowrap" style={{ fontSize: "32px" }}>
                      {blocks}
                    </span>
                  </div>
                </div>

                <div className={`flex border-b md:border-b-0 last:border-b-0 bg-primary-700 md:border-r first:border-l-0 last:border-r-0 border-gray-1000  py-4 md:py-8 md:px-16`}>
                  <div className="min-h-[48px] flex-grow flex items-center justify-between gap-10">
                    <span className="text-md uppercase">Gas Price</span>
                    <span className="font-manrope font-medium text-4xl whitespace-nowrap" style={{ fontSize: "32px" }}>
                      {'0.0001'} KEC
                    </span>
                  </div>
                </div>

                <div className={`flex border-b md:border-b-0 last:border-b-0 bg-primary-700 md:border-r first:border-l-0 last:border-r-0 border-gray-1000  py-4 md:py-8 md:px-9`}>
                  <div className="min-h-[48px] flex-grow flex items-center justify-between gap-10">
                    <span className="text-md uppercase">Wallets</span>
                    <span className="font-manrope font-medium text-4xl whitespace-nowrap" style={{ fontSize: "32px" }}>
                      {deployer}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
      <ScrollContainer>
        <div className="max-w-[1440px] hidden mx-auto md:grid grid-cols-[312px_408px_408px_312px]">
          <div className={`flex border-b md:border-b-0 last:border-b-0 bg-primary-700 md:border-r first:border-l-0 last:border-r-0 border-gray-1000  py-4 md:py-8 md:px-9`}>
            <div className="min-h-[48px] flex-grow flex items-center justify-between gap-10">
              <span className="text-md uppercase">block time</span>
              <span className="font-manrope font-medium text-4xl whitespace-nowrap" style={{ fontSize: "32px" }}>
                {average}
              </span>
            </div>
          </div>

          <div className={`flex border-b md:border-b-0 last:border-b-0 bg-primary-700 md:border-r first:border-l-0 last:border-r-0 border-gray-1000  py-4 md:py-8 md:px-14`}>
            <div className="min-h-[48px] flex-grow flex items-center justify-between gap-10">
              <span className="text-md uppercase">current block</span>
              <span className="font-manrope font-medium text-4xl whitespace-nowrap" style={{ fontSize: "32px" }}>
                {blocks}
              </span>
            </div>
          </div>

          <div className={`flex border-b md:border-b-0 last:border-b-0 bg-primary-700 md:border-r first:border-l-0 last:border-r-0 border-gray-1000  py-4 md:py-8 md:px-16`}>
            <div className="min-h-[48px] flex-grow flex items-center justify-between gap-10">
              <span className="text-md uppercase">Gas Price</span>
              <span className="font-manrope font-medium text-4xl whitespace-nowrap" style={{ fontSize: "32px" }}>
                {'0.0001'} KEC
              </span>
            </div>
          </div>

          <div className={`flex border-b md:border-b-0 last:border-b-0 bg-primary-700 md:border-r first:border-l-0 last:border-r-0 border-gray-1000  py-4 md:py-8 md:px-9`}>
            <div className="min-h-[48px] flex-grow flex items-center justify-between gap-10">
              <span className="text-md uppercase">Wallet Interacted:</span>
              <span className="font-manrope font-medium text-4xl whitespace-nowrap" style={{ fontSize: "32px" }}>
                {deployer}
              </span>
            </div>
          </div>
        </div>
      </ScrollContainer>
    </section>
  );
}

export default Statusbar;
