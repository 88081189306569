export const stableCoins = {
    97:{
        "USDT" : "0x7ef95a0FEE0Dd31b22626fA2e10Ee6A223F8a684",
        "BUSD" : "0x78867bbeef44f2326bf8ddd1941a4439382ef2a7"
    },
    56:{
        "USDT" : "0x55d398326f99059ff775485246999027b3197955",
        "BUSD" : "0xe9e7cea3dedca5984780bafc599bd69add087d56"
    },
    1:{
        "USDT" : "0xdAC17F958D2ee523a2206206994597C13D831ec7",
        "BUSD" : "0x4Fabb145d64652a948d72533023f6E7A623C7C53"
    },
    5:{
        "USDT" : "0xC2C527C0CACF457746Bd31B2a698Fe89de2b6d49",
        "BUSD" : "0x524862d19efda8a54b558fa100668a6925357ede"
    }              
}


export const BUSD = "0xe9e7cea3dedca5984780bafc599bd69add087d56";
export const USDT = "0x55d398326f99059ff775485246999027b3197955";

export const icoContract = {
    97 : "0xC8996DA76874Dd6d9332F799002E3fA1AE3cF60d",
    56 : "0xbCbdd6c8cA5c74063da858e6BE27947897538377",
    1 : "0xe0710e61EcF5CE0967f9b605136A36F769D6FD23",
    5 : "0xD116F2Fabb2C83309f8e30015b6eCd357482e0f5"
}

export const deadline = 1686355200000;