import { useEffect, useState } from 'react'
import { BIG_ZERO } from '../utils/bigNumber'
import useRefresh from './useRefresh'
import abi from 'abi/stable.json'
import icoABI from 'abi/ico.json'
import { useContract } from './useContract'
import { stableCoins } from 'config'
import useWallet from 'contexts/WalletContext'

export const useBalance = (address: string) => {
  const [balance, setBalance] = useState(BIG_ZERO)
  const { account, chainId } = useWallet();
  const { fastRefresh } = useRefresh()
  const contract = useContract(address, abi, chainId);
  useEffect(() => {

    const fetchBalance = async () => {
      try {
        const res = await contract.balanceOf(account)
        setBalance(res)
      } catch (error) {
       
        setBalance(BIG_ZERO);
      }
    }

    if (account || address) {
      fetchBalance()
    }


  }, [account, fastRefresh, address, contract])

  return balance
}

export const useDecimals = (address: string) => {
  const [decimals, setDecimals] = useState(BIG_ZERO)
  const { account, chainId } = useWallet();
    const { fastRefresh } = useRefresh()
  const contract = useContract(address, abi, chainId);
  useEffect(() => {

    const fetchDecimals = async () => {
      try {
        const res = await contract.decimals()
        setDecimals(res)
      } catch (error) {
       
        setDecimals(BIG_ZERO);
      }
    }

    if (account || address) {
      fetchDecimals()
    }


  }, [account, fastRefresh, address, chainId, contract])

  return decimals
}

export const useStable = (symbol : string) => {
  const [stable, setStable] = useState("")
  const {  chainId } = useWallet();
    const { fastRefresh } = useRefresh()
  useEffect(() => {
    const fetchStable = async () => {
      return setStable(stableCoins[chainId][symbol]);
    }

    if (chainId || chainId !== undefined) {
      fetchStable()
    }
  }, [fastRefresh, symbol, chainId])
  return stable
}

export const useRate = (address: string) => {
  const [balance, setBalance] = useState(BIG_ZERO)
  const { account, chainId } = useWallet();
    const { fastRefresh } = useRefresh()
  const contract = useContract(address, icoABI, chainId);
  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const res = await contract._rate()
        setBalance(res)
      } catch (error) {
        setBalance(BIG_ZERO);
      }
    }

    if (account || address) {
      fetchBalance()
    }


  }, [account, fastRefresh, address, chainId, contract])

  return balance
}